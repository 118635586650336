:root { 
--deep_orange_A100:#f5ab67; 
--deep_purple_800:#3d349d; 
--blue_A700:#2364ff; 
--red_300:#f56767; 
--red_401:#e05d5d; 
--red_400:#e05c5c; 
--light_blue_400:#2dc3f2; 
--green_A100:#ccffd7; 
--green_600:#27a953; 
--red_50:#ffecec; 
--black_900:#000000; 
--black_900_60:#00000060; 
--red_300_7c:#f567677c; 
--black_900_68:#00000068; 
--pink_400:#c63973; 
--yellow_100:#fffccc; 
--lime_800_87:#a5a82087; 
--gray_600:#6c6c6c; 
--gray_700:#595959; 
--gray_400:#b6b6b6; 
--orange_A200:#ffb039; 
--gray_401:#b1b1b1; 
--gray_800:#3a3a3a; 
--lime_800:#a1a325; 
--red_A200:#ff3c53; 
--gray_801:#3c3c3c; 
--orange_A200_00:#ffb03900; 
--black_900_0c:#0000000c; 
--gray_200:#eaeaea; 
--blue_50:#e5eeff; 
--gray_100:#f6f6f6; 
--cyan_400_87:#38b5c687; 
--cyan_50:#ccfaf5; 
--black_900_19:#00000019; 
--black_900_14:#00000014; 
--white_A700:#ffffff; 
--indigo_800:#20398d; 
--cyan_400:#38b5c6; 
--green_600_63:#27a95363; 
}